import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/ember-resource-routes",
  "date": "2014-12-02",
  "title": "EMBER – RESOURCE ROUTES",
  "author": "admin",
  "tags": ["development", "javascript", "ember.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "The difference between resource() and route() in Ember.js is that you should use this.resource for URLs that represent a noun, and this.route for URLs that represent adjectives or verbs modifying those nouns."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The practical difference between these two is that resource may contain other routes, whereas route cannot. Additionally, this.resource automatically creates an index route when it sees you have sub-routes.`}</p>
    <p>{`Here, the name of the controller matches the route and template names.`}</p>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.IndexController = Ember.Controller.extend({
    productsCount: 6,
    logo: '/images/test.png',
    time: function() {
    return (new Date()).toDateString();
    }.property()
});
`}</code></pre>
    <p><strong parentName="p">{`index.html`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script type='text/handlebars' data-template-name='index'>
    There are {{productsCount}} products
    <img {{bind-attr src='logo'}} alt='Logo' />
    Current Time: {{time}}
</script>
`}</code></pre>
    <p>{`Templates can use properties from a controller`}</p>
    <h2>{`Use route for adjectives, verbs, adverbs`}</h2>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.Router.map(function() {
    this.route('about');
});
`}</code></pre>
    <p><strong parentName="p">{`Adjective Routes`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.route('onsale');
this.route('expiring');
this.route('new');
this.route('internal');
`}</code></pre>
    <p><strong parentName="p">{`Verb Routes`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.route('signup');
this.route('ignite');
this.route('help');
this.route('rate');
`}</code></pre>
    <h2>{`Use `}<strong parentName="h2">{`Resource`}</strong>{` Routes for Nouns`}</h2>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.Router.map(function() {
    this.route('about');
});
this
`}</code></pre>
    <p><strong parentName="p">{`Noun Resources`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.resource('tree');
this.resource('review');
this.resource('books');
this.resource('contacts');
`}</code></pre>
    <p>{`There can be singular or plural. The resource keyword has some additional functionality.`}</p>
    <h2>{`Resource Route Options`}</h2>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.Router.map(function() {
    this.route('about');
    this.resource('products' , { path: '/items' });
});
`}</code></pre>
    <h2>{`Resource Views`}</h2>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.Router.map(function() {
    this.route('about');
});
this.
`}</code></pre>
    <p><strong parentName="p">{`index.html`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script type='text/x-handlebars' data-template-name='products'>
    <h1>Products</h1>
</script>
`}</code></pre>
    <p>{`Here the data-template-name matches the route name.`}</p>
    <h2>{`Linking to Resources`}</h2>
    <p>{`We can link to this route with its name like any other.`}</p>
    <p><strong parentName="p">{`index.html`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script type='text/x-handlebars' data-template-name='application'>
    ...
    {{#link-to 'products' tagName='li'}}Products{{/link-to}}
    ...
</script>
`}</code></pre>
    <h2>{`Ember Route`}</h2>
    <p>{`Ember Route fetches data and passes it to the Ember Controller. It decides on what model to use for the current route.
And it decides which template to render to the screen.`}</p>
    <p>{`A model could be a JavaScript Object or an Array of objects.`}</p>
    <p>{`Ember Router - Translates a path into a route.
Ember Route - Provides data for the controller.`}</p>
    <p>{`Don’t get these confused!`}</p>
    <h2>{`Router? Route? Controller?`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Router`}</strong>{` Used to define routes our application accepts`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-js"
          }}>{`    this.resource('products');
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Responsible for getting data from external resources`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-js"
          }}>{`    App.ProductsRoute = Ember.Route.extend({ });
`}</code></pre>
        <p parentName="li">{`  Created by Ember if not defined.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Decorates the model, provides property values`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-js"
          }}>{`    App.ProductsController = Ember.Controller.extend({ });
`}</code></pre>
        <p parentName="li">{`  Created by Ember if not defined.`}</p>
      </li>
    </ul>
    <h2>{`A Very Basic Model`}</h2>
    <p><strong parentName="p">{`app.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`App.PRODUCTS = [
    {
        title: 'Flint',
        price: 99,
        description: 'Flint is…',
        isOnSale: true,
        image: 'flint.png'
    },
    {
        title: 'Kindling',
        price: 249,
        description: 'Easily…',
        isOnSale: false,
        image: 'kindling.png'
    }
];

App.ProductsRoute = Ember.Route.extend({
    model: function() {
    return App.PRODUCTS;
}
});
`}</code></pre>
    <p>{`Above highlighted image could be pulled from an API. and the model property should return an
an object or an array.`}</p>
    <h2>{`Putting it All Together`}</h2>
    <p><strong parentName="p">{`Router`}</strong>{` The Route is responsible for fetching the model. Ember will set
this model on the Controller, and that’s what’ll be used in your
Handlebars Template.`}</p>
    <p><strong parentName="p">{`Route`}</strong>{` Model`}</p>
    <p><strong parentName="p">{`Controller`}</strong>{` `}</p>
    <p><strong parentName="p">{`index.html`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='products'>
    ## Products
    {{model}}
</script>
`}</code></pre>
    <h2>{`Rendering the Model`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='products'>
    ## Products
    {{model}}
</script>
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`## Products
[object Object],[object Object]
`}</code></pre>
    <p>{`Model is an array, so we’ll need to loop over it!`}</p>
    <h2>{`Looping Over the Array of Objects`}</h2>
    <p><strong parentName="p">{`index.html`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='products'>
    ## Products
    {{#each product in model}}
    ## {{product.title}}
    {{/each}}
</script>
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1>Products</h1>
## Flint
## Kindling
`}</code></pre>
    <h2>{`A Simpler `}<inlineCode parentName="h2">{`#each`}</inlineCode>{` Loop`}</h2>
    <p><inlineCode parentName="p">{`#each`}</inlineCode>{` without extra options will look for a model property.`}</p>
    <p><strong parentName="p">{`index.html`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='products'>
    ...
    {{#each}}
    ## {{title}}
    {{/each}}
</script>
`}</code></pre>
    <p>{`Within the #each loop, you can access properties on that product`}</p>
    <p><strong parentName="p">{`index.html`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type='text/x-handlebars' data-template-name='products'>
<h1>Products</h1>
<ul class='list-unstyled col-md-8'>
{{#each}}
<li class='row'>
    <img {{bind-attr src='image'}} class='img-thumbnail col-md-5'/>
    <div class='col-md-7'>
    ## {{title}}
    <p class='product-description'>{{description}}
    <button class='btn btn-success'>Buy for \${{price}}</button>
    </div>

{{/each}}

</script>
`}</code></pre>
    <p>{`Output a few more properties and add some styling.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      